export enum UserInspectorStatus {
  SUCCESSFULLY_ACTIVATED = 0,
  ALREADY_ACTIVATED,
  ERROR,
}
export interface InspectorLogin {
  email?: string;
  expirationDate: Date | null;
  status: UserInspectorStatus | null;
}
