import { useCallback, useState } from 'react';
import { Fab, Zoom, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CommentShiftAttendance from 'src/pages/routeSheet/components/CommentShiftAttendance';
import { getRouteSheet } from 'src/api/routeSheetRequest';
import { IFilters } from 'src/types/filters';
import ModalCustom from 'src/components/shared/ModalCustom';
import { RouteSheetResource } from 'src/types/routeSheet';
import { descendingComparatorDate } from 'src/utils/comparerHelper';
import MetbusLoadingRive from 'src/components/shared/loaders/MetbusLoadingRive';
import useAuth from 'src/hooks/useAuth';
import { addDays, startOfDay } from 'date-fns';
import { UserProfiles } from 'src/types/userProfiles';

const FloatingCommentWithModal = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [assistance, setAssistance] = useState<RouteSheetResource[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handle = () => {
    if (user?.profile === UserProfiles.Conductor) {
      const filters: IFilters = {
        startDate: startOfDay(addDays(new Date(), -3)),
        endDate: startOfDay(new Date()),
        ruts: [user.rut],
        terminals: [],
        includeSettled: true,
        shifts: [],
        status: null,
      };
      getData(filters);
    }
  };

  const getData = useCallback(
    (params: IFilters) => {
      setLoading(true);
      let assistances;

      getRouteSheet(params)
        .then((response) => {
          if (response.status === 200) {
            assistances = response.data.sort((a, b) =>
              descendingComparatorDate(
                new Date(a.attendanceStart ?? ''),
                new Date(b.attendanceStart ?? '')
              )
            );
            setAssistance(assistances);
            setOpen(true);
          } else setAssistance([]);
        })
        .catch(() => {
          // SnackbarUtils.error(getErrorMessage(err));
          setAssistance([]);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [getRouteSheet]
  );

  return (
    <>
      {loading && <MetbusLoadingRive />}
      <Zoom
        in={!open}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`,
        }}
        unmountOnExit
      >
        <Fab
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
          }}
          aria-label="Edit"
          color="secondary"
          onClick={handle}
          variant="extended"
        >
          <EditIcon />
          Observaciones de Ruta
        </Fab>
      </Zoom>
      {!loading && (
        <ModalCustom
          open={open}
          width="700"
          handleClose={handleClose}
        >
          <CommentShiftAttendance assistance={assistance} />
        </ModalCustom>
      )}
    </>
  );
};

export default FloatingCommentWithModal;
