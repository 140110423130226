import { Stack, useMediaQuery, useTheme } from '@mui/material';
import LoadingButton from 'src/components/shared/LoadingButton';

interface ButtonCommentProps {
  loading: boolean;
  disabled?: boolean;
  onClick: () => void;
  observations: string | null;
}
const ButtonComment = ({
  loading,
  disabled,
  onClick,
  observations,
}: ButtonCommentProps) => {
  const theme = useTheme();
  const isSmDisplay = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      direction="row"
      justifyContent={isSmDisplay ? 'end' : 'center'}
      alignItems="flex-end"
      padding={1}
      flexGrow={1}
    >
      <LoadingButton
        variant="contained"
        size="medium"
        loading={loading}
        disabled={disabled}
        color={
          typeof observations === 'string' && observations.length > 0
            ? 'warning'
            : 'primary'
        }
        onClick={onClick}
      >
        {typeof observations === 'string' && observations.length > 0
          ? 'Guardar'
          : 'Ingresar'}
      </LoadingButton>
    </Stack>
  );
};

export default ButtonComment;
