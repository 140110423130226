import { CSSProperties } from 'react';

const iconStyles: CSSProperties = {
  minHeight: '200px',
  minWidth: '200px',
  aspectRatio: '1/1',
  margin: 'auto',
};

export default iconStyles;
