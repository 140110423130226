import { Fab, Zoom, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';

interface FloatingProps {
  isPath: string | undefined;
}
const FloatingComment = ({ isPath }: FloatingProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {
    if (isPath === undefined) {
      setOpen(true);
    }
    if (isPath === 'Observaciones') {
      setOpen(false);
    }
  }, [isPath]);

  return (
    <>
      <Zoom
        in={open}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`,
        }}
        unmountOnExit
      >
        <Link to={'/annexes/commentShiftAttendance'}>
          <Fab
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
            }}
            aria-label="Edit"
            color="secondary"
          >
            <EditIcon />
          </Fab>
        </Link>
      </Zoom>
    </>
  );
};

export default FloatingComment;
