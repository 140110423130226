import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface AccountLoaderProps {
  isInspector: boolean;
}
const AccountLoader = ({ isInspector }: AccountLoaderProps) => {
  return (
    <>
      <Typography
        color="textPrimary"
        variant="h4"
        marginBottom={3}
      >
        {isInspector ? 'Acceso Inspector' : 'Acceso'}
      </Typography>
      <CircularProgress
        size={80}
        thickness={3}
        sx={{
          marginX: 'auto',
        }}
      />
      <Typography
        color="textPrimary"
        variant="body1"
        marginTop={4}
        marginX="auto"
      >
        Estamos procesando su solicitud
      </Typography>
    </>
  );
};

export default AccountLoader;
