import { create } from 'src/store/createStore';
import { DriverResource } from 'src/types/drivers';
import { CostCentre } from 'src/types/costCentre';
import { persist, createJSONStorage } from 'zustand/middleware';
import { DriverPeriodResource } from 'src/pages/deals/types/drivers';

interface CommonDataState {
  driverResource: DriverResource[];
  driverResourceFilters: DriverResource[];
  terminus: CostCentre[];
  dealPeriods: DriverPeriodResource[];
  setDriverResourceFilters: (data: DriverResource[]) => void;
  setDriverResource: (data: DriverResource[]) => void;
  setTerminus: (data: CostCentre[]) => void;
  setDealPeriods: (data: DriverPeriodResource[]) => void;
  reset: () => void;
}

export const commonDataStore = create<CommonDataState>()(
  persist(
    (set) => ({
      driverResource: [],
      driverResourceFilters: [],
      terminus: [],
      dealPeriods: [],
      setDriverResourceFilters: (data: DriverResource[]) => {
        set({ driverResourceFilters: data });
      },
      setDriverResource: (data: DriverResource[]) => {
        set({ driverResource: data });
      },
      setTerminus: (data: CostCentre[]) => {
        set({ terminus: data });
      },
      setDealPeriods: (data: DriverPeriodResource[]) => {
        set({ dealPeriods: data });
      },
      reset: () => {
        set({
          driverResource: [],
          terminus: [],
        });
      },
    }),
    {
      name: 'common-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
