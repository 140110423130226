export enum AttendanceType {
  // [Description("Falta")]
  Absence = 0,
  // [Description("Asistencia")]
  Worked = 1,
  // [Description("Día Libre")]
  DayOff = 2,
  // [Description("Vacaciones")]
  Vacation = 3,
  // [Description("Permiso")]
  Permission = 4,
  MedicalLicense = 5,
  // [Description("Turno Extra")]
  ExtraShift = 6,
  // [Description("Capacitación")]
  Training = 8,
}
