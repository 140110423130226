/* eslint-disable react/jsx-first-prop-new-line */
import createSvgIcon from '@mui/material/utils/createSvgIcon';

const LogoDT = createSvgIcon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 124 124"
  >
    <path
      fill="#F1F1F0"
      d="M7.347,61.998C7.347,31.814,31.816,7.345,62,7.345c30.184,0,54.653,24.469,54.653,54.653
    c0,30.184-24.469,54.653-54.653,54.653C31.816,116.651,7.347,92.182,7.347,61.998"
    />
    <path
      fill="#EB3C46"
      d="M62,0c-4.748,0-9.37,0.536-13.811,1.546l1.858,8.134C53.891,8.805,57.891,8.341,62,8.341
    c29.635,0,53.659,24.024,53.659,53.659S91.635,115.659,62,115.659c-4.113,0-8.118-0.464-11.966-1.341l-1.86,8.133
    C52.619,123.463,57.247,124,62,124c34.242,0,62-27.758,62-62S96.241,0,62,0"
    />
    <path
      fill="#0F69B4"
      d="M8.341,62c0-25.526,17.824-46.887,41.705-52.32l-1.858-8.134C20.595,7.824,0,32.506,0,62
    c0,29.488,20.587,54.167,48.173,60.45l1.86-8.133C26.159,108.879,8.341,87.521,8.341,62"
    />
    <path
      fill="#838384"
      d="M53.075,31.85c3.018,0.992,5.461,2.812,7.33,5.457c1.5,2.14,2.521,4.455,3.066,6.944
    c0.545,2.49,0.818,4.863,0.818,7.12c0,5.719-1.149,10.563-3.446,14.531c-3.115,5.35-7.924,8.024-14.426,8.024H27.874v-43.01h18.543
    C49.085,30.955,51.304,31.266,53.075,31.85 M36.598,38.386v28.071h8.3c4.248,0,7.209-2.091,8.884-6.274
    c0.916-2.295,1.374-5.028,1.374-8.199c0-4.377-0.687-7.737-2.06-10.081c-1.374-2.344-4.106-3.517-8.198-3.517H36.598z"
    />
    <polygon
      fill="#838384"
      points="96.126,30.916 96.126,38.532 83.258,38.532 83.258,73.926 74.212,73.926 74.212,38.532
    61.286,38.532 61.286,30.916 "
    />
    <path
      fill="#838384"
      d="M35,80.438c0.516,0.169,0.933,0.48,1.252,0.932c0.256,0.366,0.431,0.761,0.523,1.187
    c0.093,0.425,0.14,0.831,0.14,1.216c0,0.977-0.197,1.804-0.589,2.482c-0.532,0.914-1.353,1.371-2.464,1.371h-3.168v-7.348h3.168
    C34.319,80.285,34.698,80.338,35,80.438 M32.186,81.555v4.795h1.418c0.726,0,1.231-0.357,1.518-1.071
    c0.157-0.393,0.235-0.859,0.235-1.401c0-0.748-0.118-1.322-0.352-1.722c-0.235-0.4-0.701-0.601-1.4-0.601H32.186z"
    />
    <path
      fill="#838384"
      d="M39.414,81.544h-1.44v-1.311h1.44V81.544z M37.973,82.192h1.44v5.434h-1.44V82.192z"
    />
    <path
      fill="#838384"
      d="M43.808,82.065c0.019,0.002,0.059,0.004,0.122,0.007v1.456c-0.09-0.01-0.169-0.017-0.239-0.02
    c-0.07-0.003-0.126-0.005-0.169-0.005c-0.572,0-0.956,0.186-1.151,0.558c-0.109,0.209-0.165,0.532-0.165,0.967v2.597h-1.431v-5.434
    h1.355v0.947c0.22-0.362,0.411-0.609,0.574-0.743c0.266-0.223,0.611-0.334,1.037-0.334C43.767,82.063,43.79,82.064,43.808,82.065"
    />
    <path
      fill="#838384"
      d="M48.303,82.303c0.379,0.17,0.692,0.438,0.939,0.804c0.223,0.323,0.367,0.697,0.433,1.123
    c0.038,0.25,0.054,0.609,0.047,1.079h-3.958c0.022,0.545,0.211,0.927,0.568,1.146c0.217,0.136,0.478,0.204,0.783,0.204
    c0.324,0,0.586-0.083,0.789-0.249c0.11-0.09,0.208-0.214,0.293-0.374h1.45c-0.039,0.322-0.214,0.649-0.527,0.981
    c-0.487,0.529-1.168,0.793-2.044,0.793c-0.723,0-1.361-0.223-1.914-0.668c-0.553-0.446-0.829-1.171-0.829-2.175
    c0-0.941,0.249-1.663,0.748-2.166c0.499-0.502,1.147-0.753,1.943-0.753C47.498,82.048,47.924,82.133,48.303,82.303 M46.178,83.53
    c-0.201,0.207-0.327,0.487-0.379,0.841h2.448c-0.026-0.377-0.152-0.664-0.378-0.858c-0.227-0.195-0.507-0.293-0.843-0.293
    C46.661,83.219,46.378,83.323,46.178,83.53"
    />
    <path
      fill="#838384"
      d="M53.69,84.142c-0.027-0.203-0.095-0.385-0.205-0.548c-0.16-0.219-0.408-0.329-0.745-0.329
    c-0.48,0-0.808,0.237-0.984,0.712c-0.093,0.252-0.14,0.587-0.14,1.004c0,0.398,0.047,0.718,0.14,0.959
    c0.17,0.452,0.49,0.678,0.959,0.678c0.333,0,0.57-0.09,0.709-0.27c0.14-0.179,0.225-0.412,0.255-0.698h1.45
    c-0.033,0.432-0.189,0.841-0.469,1.226c-0.446,0.622-1.106,0.933-1.981,0.933s-1.519-0.259-1.931-0.778
    c-0.413-0.518-0.619-1.19-0.619-2.016c0-0.933,0.228-1.658,0.684-2.176c0.456-0.518,1.084-0.777,1.886-0.777
    c0.682,0,1.24,0.153,1.674,0.458c0.434,0.306,0.691,0.846,0.771,1.62H53.69z"
    />
    <path
      fill="#838384"
      d="M59.368,84.142c-0.026-0.203-0.095-0.385-0.205-0.548c-0.16-0.219-0.408-0.329-0.744-0.329
    c-0.48,0-0.808,0.237-0.984,0.712c-0.093,0.252-0.14,0.587-0.14,1.004c0,0.398,0.047,0.718,0.14,0.959
    c0.17,0.452,0.489,0.678,0.959,0.678c0.333,0,0.57-0.09,0.71-0.27c0.14-0.179,0.225-0.412,0.254-0.698h1.451
    c-0.033,0.432-0.189,0.841-0.469,1.226c-0.446,0.622-1.106,0.933-1.981,0.933c-0.875,0-1.519-0.259-1.931-0.778
    c-0.413-0.518-0.619-1.19-0.619-2.016c0-0.933,0.228-1.658,0.684-2.176c0.456-0.518,1.084-0.777,1.886-0.777
    c0.682,0,1.24,0.153,1.674,0.458s0.691,0.846,0.771,1.62H59.368z"
    />
    <path
      fill="#838384"
      d="M63.256,81.544h-1.44v-1.311h1.44V81.544z M61.815,82.192h1.441v5.434h-1.441V82.192z"
    />
    <path
      fill="#838384"
      d="M69.163,86.961c-0.458,0.566-1.155,0.849-2.088,0.849c-0.934,0-1.63-0.283-2.089-0.849
    c-0.458-0.566-0.688-1.248-0.688-2.045c0-0.783,0.229-1.462,0.688-2.037c0.459-0.575,1.155-0.862,2.089-0.862
    c0.934,0,1.63,0.287,2.088,0.862c0.459,0.574,0.688,1.253,0.688,2.037C69.851,85.714,69.622,86.396,69.163,86.961 M68.026,86.176
    c0.223-0.295,0.334-0.715,0.334-1.26c0-0.544-0.111-0.963-0.334-1.256c-0.223-0.294-0.542-0.441-0.957-0.441
    c-0.416,0-0.735,0.147-0.959,0.441c-0.225,0.293-0.337,0.712-0.337,1.256c0,0.544,0.112,0.964,0.337,1.26
    c0.224,0.295,0.544,0.443,0.959,0.443C67.484,86.619,67.804,86.472,68.026,86.176 M68.774,79.969l-1.515,1.491h-1.027l0.957-1.491
    H68.774z"
    />
    <path
      fill="#838384"
      d="M75.249,82.504c0.355,0.294,0.534,0.782,0.534,1.463v3.659h-1.456v-3.305c0-0.286-0.038-0.505-0.114-0.658
    c-0.139-0.279-0.403-0.418-0.792-0.418c-0.479,0-0.807,0.204-0.985,0.613c-0.092,0.216-0.139,0.492-0.139,0.828v2.941h-1.42v-5.424
    h1.376v0.793c0.182-0.279,0.354-0.48,0.516-0.603c0.291-0.219,0.66-0.329,1.107-0.329C74.436,82.063,74.893,82.21,75.249,82.504"
    />
    <path
      fill="#838384"
      d="M82.821,82.28c0.256,0.145,0.463,0.345,0.623,0.601v-2.592h1.44v7.338h-1.381v-0.753
    c-0.203,0.323-0.434,0.557-0.693,0.703c-0.259,0.146-0.582,0.219-0.967,0.219c-0.635,0-1.169-0.257-1.603-0.77
    c-0.434-0.514-0.651-1.172-0.651-1.977c0-0.927,0.214-1.657,0.641-2.188c0.427-0.531,0.998-0.797,1.712-0.797
    C82.273,82.063,82.565,82.135,82.821,82.28 M83.17,86.15c0.209-0.299,0.314-0.686,0.314-1.161c0-0.665-0.167-1.14-0.503-1.426
    c-0.206-0.173-0.445-0.259-0.718-0.259c-0.415,0-0.72,0.157-0.915,0.471c-0.195,0.313-0.292,0.704-0.292,1.169
    c0,0.502,0.099,0.903,0.296,1.204s0.498,0.451,0.9,0.451C82.655,86.599,82.961,86.449,83.17,86.15"
    />
    <path
      fill="#838384"
      d="M89.717,82.303c0.379,0.17,0.691,0.438,0.938,0.804c0.223,0.323,0.367,0.697,0.433,1.123
    c0.039,0.25,0.054,0.609,0.047,1.079h-3.957c0.022,0.545,0.211,0.927,0.568,1.146c0.217,0.136,0.478,0.204,0.783,0.204
    c0.324,0,0.587-0.083,0.789-0.249c0.11-0.09,0.208-0.214,0.292-0.374h1.45c-0.038,0.322-0.214,0.649-0.526,0.981
    c-0.487,0.529-1.168,0.793-2.044,0.793c-0.723,0-1.361-0.223-1.914-0.668c-0.553-0.446-0.829-1.171-0.829-2.175
    c0-0.941,0.25-1.663,0.749-2.166c0.499-0.502,1.147-0.753,1.943-0.753C88.912,82.048,89.337,82.133,89.717,82.303 M87.591,83.53
    c-0.201,0.207-0.327,0.487-0.379,0.841h2.448c-0.026-0.377-0.152-0.664-0.378-0.858c-0.227-0.195-0.507-0.293-0.843-0.293
    C88.075,83.219,87.792,83.323,87.591,83.53"
    />
    <rect
      x="91.884"
      y="80.278"
      fill="#838384"
      width="1.42"
      height="7.348"
    />
    <polygon
      fill="#838384"
      points="49.932,92.529 49.932,93.83 47.733,93.83 47.733,99.876 46.188,99.876 46.188,93.83 43.979,93.83
      43.979,92.529 "
    />
    <path
      fill="#838384"
      d="M53.174,94.316c0.019,0.002,0.059,0.004,0.122,0.007v1.456c-0.089-0.01-0.169-0.017-0.239-0.02
    c-0.069-0.003-0.126-0.005-0.169-0.005c-0.572,0-0.956,0.186-1.152,0.558c-0.109,0.209-0.164,0.532-0.164,0.967v2.597h-1.431v-5.433
    h1.356v0.947c0.219-0.362,0.411-0.609,0.573-0.743c0.266-0.223,0.612-0.334,1.037-0.334C53.133,94.313,53.156,94.314,53.174,94.316"
    />
    <path
      fill="#838384"
      d="M56.281,96.572c0.264-0.033,0.454-0.075,0.567-0.125c0.204-0.086,0.306-0.221,0.306-0.404
    c0-0.223-0.078-0.376-0.235-0.461c-0.157-0.084-0.386-0.127-0.69-0.127c-0.34,0-0.581,0.083-0.722,0.25
    c-0.101,0.123-0.168,0.289-0.202,0.499h-1.371c0.03-0.476,0.164-0.866,0.401-1.172c0.377-0.479,1.024-0.718,1.942-0.718
    c0.597,0,1.128,0.118,1.592,0.354c0.463,0.236,0.696,0.682,0.696,1.336v2.493c0,0.172,0.003,0.382,0.01,0.628
    c0.01,0.186,0.038,0.312,0.084,0.378c0.047,0.067,0.116,0.122,0.209,0.165v0.209h-1.545c-0.043-0.109-0.073-0.212-0.089-0.309
    c-0.017-0.096-0.03-0.206-0.04-0.329c-0.197,0.213-0.424,0.394-0.682,0.543c-0.308,0.176-0.655,0.264-1.042,0.264
    c-0.495,0-0.904-0.141-1.226-0.421c-0.323-0.281-0.484-0.679-0.484-1.194c0-0.668,0.26-1.152,0.778-1.451
    c0.285-0.163,0.703-0.279,1.255-0.349L56.281,96.572z M57.149,97.234c-0.091,0.057-0.183,0.103-0.275,0.137s-0.22,0.067-0.382,0.097
    l-0.323,0.06c-0.303,0.054-0.521,0.118-0.653,0.195c-0.224,0.129-0.335,0.331-0.335,0.603c0,0.243,0.068,0.418,0.205,0.526
    c0.137,0.108,0.303,0.162,0.499,0.162c0.311,0,0.597-0.09,0.858-0.269c0.261-0.18,0.397-0.507,0.407-0.982V97.234z"
    />
    <path
      fill="#838384"
      d="M64.446,95.096c0.406,0.515,0.609,1.18,0.609,1.994c0,0.845-0.2,1.544-0.601,2.099
    c-0.401,0.555-0.96,0.833-1.679,0.833c-0.451,0-0.813-0.089-1.087-0.269c-0.164-0.106-0.341-0.292-0.531-0.558v0.683h-1.401v-7.337
    h1.421v2.612c0.181-0.252,0.379-0.445,0.597-0.578c0.258-0.166,0.586-0.25,0.983-0.25C63.477,94.323,64.04,94.581,64.446,95.096
    M63.258,98.406c0.205-0.295,0.307-0.684,0.307-1.166c0-0.386-0.05-0.705-0.151-0.957c-0.191-0.479-0.543-0.718-1.056-0.718
    c-0.519,0-0.876,0.234-1.07,0.703c-0.101,0.249-0.151,0.572-0.151,0.967c0,0.466,0.104,0.851,0.312,1.157
    c0.208,0.306,0.524,0.458,0.95,0.458C62.768,98.85,63.054,98.702,63.258,98.406"
    />
    <path
      fill="#838384"
      d="M68.194,96.572c0.264-0.033,0.454-0.075,0.567-0.125c0.204-0.086,0.306-0.221,0.306-0.404
    c0-0.223-0.078-0.376-0.235-0.461c-0.156-0.084-0.387-0.127-0.689-0.127c-0.34,0-0.581,0.083-0.723,0.25
    c-0.101,0.123-0.168,0.289-0.202,0.499h-1.371c0.03-0.476,0.163-0.866,0.4-1.172c0.377-0.479,1.025-0.718,1.943-0.718
    c0.597,0,1.128,0.118,1.592,0.354s0.696,0.682,0.696,1.336v2.493c0,0.172,0.003,0.382,0.01,0.628
    c0.01,0.186,0.038,0.312,0.085,0.378c0.046,0.067,0.116,0.122,0.209,0.165v0.209h-1.545c-0.043-0.109-0.074-0.212-0.09-0.309
    c-0.016-0.096-0.029-0.206-0.04-0.329c-0.197,0.213-0.425,0.394-0.682,0.543c-0.307,0.176-0.655,0.264-1.042,0.264
    c-0.495,0-0.904-0.141-1.226-0.421c-0.322-0.281-0.484-0.679-0.484-1.194c0-0.668,0.259-1.152,0.778-1.451
    c0.285-0.163,0.703-0.279,1.255-0.349L68.194,96.572z M69.063,97.234c-0.091,0.057-0.183,0.103-0.275,0.137s-0.22,0.067-0.381,0.097
    l-0.324,0.06c-0.303,0.054-0.521,0.118-0.653,0.195c-0.224,0.129-0.336,0.331-0.336,0.603c0,0.243,0.069,0.418,0.206,0.526
    c0.137,0.108,0.303,0.162,0.499,0.162c0.31,0,0.596-0.09,0.857-0.269c0.262-0.18,0.397-0.507,0.408-0.982V97.234z"
    />
    <path
      fill="#838384"
      d="M71.102,100.918c0.08,0.007,0.136,0.011,0.17,0.013c0.033,0.002,0.063,0.003,0.089,0.003
    c0.14,0,0.245-0.034,0.317-0.103c0.071-0.068,0.107-0.2,0.107-0.396v-5.966h1.421v5.992c0,0.548-0.108,0.954-0.324,1.216
    c-0.216,0.263-0.64,0.394-1.271,0.394c-0.043,0-0.107-0.001-0.192-0.005c-0.085-0.003-0.19-0.007-0.317-0.01V100.918z
    M73.206,93.795h-1.421v-1.311h1.421V93.795z"
    />
    <path
      fill="#838384"
      d="M79.088,99.212c-0.459,0.566-1.155,0.849-2.089,0.849c-0.934,0-1.63-0.283-2.089-0.849
    c-0.458-0.566-0.688-1.248-0.688-2.044c0-0.784,0.229-1.462,0.688-2.037c0.458-0.574,1.155-0.862,2.089-0.862
    c0.934,0,1.63,0.288,2.089,0.862c0.458,0.575,0.688,1.253,0.688,2.037C79.775,97.964,79.546,98.646,79.088,99.212 M77.951,98.427
    c0.223-0.295,0.334-0.715,0.334-1.259s-0.111-0.963-0.334-1.257s-0.542-0.441-0.957-0.441c-0.416,0-0.735,0.147-0.959,0.441
    c-0.225,0.293-0.336,0.712-0.336,1.257s0.111,0.964,0.336,1.259c0.224,0.295,0.544,0.443,0.959,0.443
    C77.409,98.87,77.729,98.722,77.951,98.427"
    />
  </svg>,
  'LogoDT'
);

export default LogoDT;
