export const capitalizeString = (s: string) => {
  const space = ' ';
  const parsedString = s.split(space);
  let result = '';
  parsedString.forEach((p, index) => {
    if (p.length > 1) {
      result += p.charAt(0).toUpperCase() + p.slice(1).toLowerCase();
      if (index !== parsedString.length - 1) result += space;
    }
  });
  return result;
};

// export const formatRut = (s: string) => {
//   const rut = s.split('-');
//   let result = rut[0]
//     .split('')
//     .reverse()
//     .reduce((oldC, newC, index) => {
//       if (index % 3 === 0 && index !== 0) oldC += `.${newC}`;
//       else oldC += newC;
//       return oldC;
//     }, '');
//   result = result.split('').reverse().join('');
//   return `${result}-${rut[1]}`;
// };

export const formatAccentMark = (s: string) =>
  s.split('').reduce((oldValue, newValue) => {
    switch (newValue) {
      case 'á':
        return `${oldValue}a`;
      case 'é':
        return `${oldValue}e`;
      case 'í':
        return `${oldValue}i`;
      case 'ó':
        return `${oldValue}o`;
      case 'ú':
        return `${oldValue}u`;
      default:
        return `${oldValue}${newValue}`;
    }
  }, '');

export const removeAccents = (str: string) => {
  const acentos: Record<string, string> = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
  };
  return str
    .split('')
    .map((letra) => acentos[letra] || letra)
    .join('')
    .toString();
};

export function dateSplit(dateString: string, position: number): string {
  const split = dateString.split(' ');
  return split[position];
}

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function splitName(name: string) {
  // const split = name.split(' ');
  // if (split.length > 1) {
  //   return `${split[0][0]}${split[1][0]}`;
  // }
  // return `${split[0][0]}`;
  if (name.includes(' ')) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  } else {
    return name[0];
  }
}
