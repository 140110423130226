import {
  Box,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { updateDriverDispatchObservation } from 'src/api/routeSheetRequest';
import ButtonComment from 'src/pages/routeSheet/components/ButtonComment';
import Item from 'src/pages/routeSheet/components/Item';
import { DriverObservation, ObservationType } from 'src/types/filters';
import { DispatchResource } from 'src/types/routeSheet';
import { formatTime } from 'src/utils/datetimeHelper';

interface CardDispatch {
  rut: string;
  attendanceStart: Date;
  dispatch: DispatchResource;
  maxCharacter: number;
}
const CardDispatch = ({
  rut,
  attendanceStart,
  dispatch,
  maxCharacter,
}: CardDispatch) => {
  const theme = useTheme();

  const isSmDisplay = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  const [observationValue, setObservationValue] = useState<string>(
    dispatch.driverObservation
  );

  const onClick = () => {
    setLoading(true);
    const param: DriverObservation = {
      rut: rut,
      ObservationType: ObservationType.Dispatch,
      attendanceDate: attendanceStart,
      id: Number(dispatch.id),
      observation: observationValue,
    };
    updateDriverDispatchObservation(param)
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  };

  const validateDisable = () => {
    if (observationValue.trim().length === 0) return true;
    if (observationValue.trim().length <= 5) return true;
    if (observationValue.trim().length > maxCharacter) return true;
    if (observationValue.trim() === dispatch.driverObservation.trim())
      return true;
    return false;
  };

  return (
    <Box pb={1}>
      <Item>
        <Stack
          direction={isSmDisplay ? 'column-reverse' : 'row'}
          spacing={2}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="column"
            justifyContent={'flex-start'}
            flexGrow={1}
          >
            <TextField
              id="outlined-multiline-static"
              label="Comentario Despacho"
              multiline
              rows={4}
              onChange={(e) => setObservationValue(e.target.value)}
              color={
                observationValue.trim() !== dispatch.driverObservation.trim()
                  ? 'warning'
                  : 'primary'
              }
              value={observationValue}
              helperText={
                <Box
                  component="span"
                  sx={{
                    display: 'flex',
                    justifyContent:
                      (observationValue.length <= 5 &&
                        observationValue.length > 0) ||
                      observationValue.length > maxCharacter
                        ? 'space-between'
                        : 'flex-end',
                    color:
                      (observationValue.length <= 5 &&
                        observationValue.length > 0) ||
                      observationValue.length > maxCharacter
                        ? 'red'
                        : theme.palette.text.primary,
                  }}
                >
                  {observationValue.length <= 5 &&
                    observationValue.length > 0 && (
                      <span>Mínimo 5 caracteres</span>
                    )}
                  {observationValue.length > maxCharacter && (
                    <span>Excede el máximo de caracteres</span>
                  )}

                  <span>{`${observationValue.length} / ${maxCharacter}`}</span>
                </Box>
              }
            />
          </Stack>
          <Stack
            aria-labelledby="category-dispatch"
            sx={{ p: 1, width: isSmDisplay ? 280 : 165 }}
          >
            <Typography
              variant="inherit"
              sx={{
                textAlign: 'left',
              }}
            >
              <strong>Inicio: </strong>
              {`${formatTime(dispatch.startDate)}`}
            </Typography>
            <Typography
              variant="inherit"
              sx={{
                textAlign: 'left',
              }}
            >
              <strong>Termino: </strong>
              {dispatch.endDate && `${formatTime(dispatch.endDate)}`}
            </Typography>
            <Typography
              variant="inherit"
              sx={{
                textAlign: 'left',
              }}
            >
              <strong>Bus: </strong>
              {dispatch.bus}
              <span> - </span>
              {dispatch.ppu}
            </Typography>
            <Typography
              variant="inherit"
              sx={{
                textAlign: 'left',
              }}
            >
              <strong>Servicio: </strong>
              {dispatch.route}
            </Typography>
            {!isSmDisplay && (
              <ButtonComment
                loading={loading}
                disabled={validateDisable()}
                observations={dispatch.driverObservation}
                onClick={onClick}
              />
            )}
          </Stack>
        </Stack>
        {isSmDisplay && (
          <ButtonComment
            loading={loading}
            disabled={validateDisable()}
            observations={dispatch.driverObservation}
            onClick={onClick}
          />
        )}
      </Item>
    </Box>
  );
};

export default CardDispatch;
