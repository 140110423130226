import BasicModal from 'components/shared/BasicModal';
import UserForm from './UserForm';
import { Typography, useTheme } from '@mui/material';
import animationPulse from 'components/css/animationPulse';
import { useState } from 'react';

const NewPasswordModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const { palette } = useTheme();
  const handleClose = (): void => setOpen(false);

  return (
    <>
      <Typography
        color="textPrimary"
        fontWeight={400}
        fontSize={12.5}
        onClick={handleOpen}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 'auto',
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          '&:hover': {
            color: palette.primary.main,
            transition: 'all 0.3s ease-in',
            animation: `${animationPulse} 0.5s ease`,
          },
        }}
      >
        ¿Ha olvidado su contraseña?
      </Typography>
      {open && (
        <BasicModal
          open={open}
          handleClose={handleClose}
        >
          <UserForm handleClose={handleClose} />
        </BasicModal>
      )}
    </>
  );
};

export default NewPasswordModal;
