import React from 'react';
import { OptionsObject, ProviderContext, useSnackbar } from 'notistack';

let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

const defaultOptions: OptionsObject = {
  autoHideDuration: 6000,
};

export const SnackbarUtils = {
  success(msg: string, options: OptionsObject = defaultOptions) {
    this.toast(msg, { ...options, variant: 'success' });
  },
  warning(msg: string, options: OptionsObject = defaultOptions) {
    this.toast(msg, { ...options, variant: 'warning' });
  },
  info(msg: string, options: OptionsObject = defaultOptions) {
    this.toast(msg, { ...options, variant: 'info' });
  },
  error(msg: string, options: OptionsObject = defaultOptions) {
    this.toast(msg, { ...options, variant: 'error' });
  },
  toast(msg: string, options: OptionsObject = defaultOptions) {
    useSnackbarRef.enqueueSnackbar(msg, options);
  },
};
