import { useEffect, type FC } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useSettings from 'hooks/useSettings';
import VersionDisplayer from 'components/shared/VersionDisplayer';
import MetbusLogo from '../../components/MetbusLogo';
import { THEMES } from '../../constants';
import useHelmet from 'src/hooks/useHelmet';
import useAuth from 'src/hooks/useAuth';
import { LoginType } from 'src/types/loginData';
import CardLogin from 'src/components/authentication/login/CardLogin';
import DecorativePattern from 'src/icons/DecorativePattern';

const Login: FC = () => {
  const { settings } = useSettings();
  const { setLoginType } = useAuth();

  let contrastColor = false;
  if (settings.theme === THEMES.METBUSLIGHT) {
    contrastColor = true;
  }

  useEffect(() => {
    setLoginType(LoginType.NORMAL);
  }, []);

  useHelmet('Inicio de Sesión | Metbus');
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        py: '1rem',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
          '@media screen and (max-width: 770px)': {
            flexDirection: 'column',
          },
        }}
      >
        <MetbusLogo
          width="250"
          contrastColor={contrastColor}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <CardLogin>
            <Button
              component={RouterLink}
              to="/inspectorDT"
              variant="outlined"
              color="primary"
              size="large"
              sx={{
                width: '90%',
                marginInline: 'auto',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                '@media screen and (max-width: 330px)': {
                  fontSize: '12px',
                },
                '&:hover': {
                  color: '#0093b3',
                  transition: 'all 0.3s ease',
                },
              }}
            >
              Acceso Inspector DT
            </Button>
          </CardLogin>
          <VersionDisplayer horizontal />
        </Box>
      </Stack>
      <DecorativePattern
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          aspectRatio: '1/1',
          width: 'calc(20% + 100px)',
          height: 'auto',
        }}
      />
      <DecorativePattern
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          transform: 'rotate(180deg)',
          aspectRatio: '1/1',
          width: 'calc(20% + 30px)',
          height: 'auto',
        }}
      />
    </Box>
  );
};

export default Login;
