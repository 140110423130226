// export interface LoginMessage {
//   loginMessage: string;
//   loginTime: string;
// }

// export interface LoginData {
//   rut: string;
//   email: string;
//   logs: LoginMessage[];
// }

export enum LoginType {
  NORMAL = 'normal',
  INSPECTOR = 'inspector',
}
