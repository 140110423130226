import { Button, Container } from '@mui/material';
import React from 'react';
import TypographyTheme from 'src/components/custom/metbusComponents/TypographyTheme';

interface RenderOnErrorParams {
  hasError: boolean;
  error?: Error;
  resetError: () => void;
}
interface ErrorBoundaryProps {
  children: React.ReactNode;
  renderOnError?: ({
    hasError,
    error,
    resetError,
  }: RenderOnErrorParams) => React.ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}
class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { hasError: false, error: undefined };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  resetError = () => {
    this.setState({ hasError: false });
  };

  componentDidCatch(error: Error) {
    // eslint-disable-next-line no-console
    console.error('There as an error', error);
    this.setState({ error });
  }

  render() {
    if (this.state.hasError && !this.props.renderOnError) {
      return (
        <Container
          maxWidth="lg"
          sx={{ position: 'sticky', padding: '2rem' }}
        >
          <TypographyTheme variant="overline">
            Ups, hubo un error: {this.state.error?.message}
          </TypographyTheme>
          <Button
            variant="outlined"
            onClick={this.resetError}
          >
            Recargar Pagina
          </Button>
        </Container>
      );
    }

    if (this.state.hasError && this.props.renderOnError) {
      return this.props.renderOnError({
        hasError: this.state.hasError,
        error: this.state.error,
        resetError: this.resetError,
      });
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
