import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { resetPassword } from 'api/authenticate';
import animationPulse from 'components/css/animationPulse';
import { useFormik } from 'formik';
import { useState } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import iconStyles from 'components/css/iconStyle';

import * as Yup from 'yup';
import { Button } from '@mui/material';
import AccountLoader from 'src/components/authentication/inspectorAccount/AccountLoader';
import { AxiosError } from 'axios';
import ErrorMessage from 'src/components/authentication/ErrorMessage';

enum SuccessPasswordType {
  PASSWORD_RESET_OK,
  PASSWORD_RESET_ERROR,
}

interface UserFormProps {
  handleClose: () => void;
  email?: string;
}
const UserForm = ({ handleClose, email }: UserFormProps) => {
  const [loading, setLoading] = useState(false);
  const [successPassword, setSuccessPassword] =
    useState<SuccessPasswordType | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const inspectorDTEmail = /^[a-z\d]+(\.[a-z\d]+|_[a-z\d]+)*@dt\.gob\.cl$/i;

  const formik = useFormik({
    initialValues: {
      userName: email ?? '',
    },
    validationSchema: Yup.object({
      userName: Yup.string().required('Debe ingresar su usuario'),
    }),
    onSubmit: ({ userName }) => {
      setLoading(true);
      resetPassword(userName)
        .then((response) => {
          if (response.status === 200) {
            setSuccessPassword(SuccessPasswordType.PASSWORD_RESET_OK);
            setErrorMessage(null);
          } else {
            setSuccessPassword(SuccessPasswordType.PASSWORD_RESET_ERROR);
            setErrorMessage(
              'Ha ocurrido un error inesperado. Por favor intente mas tarde.'
            );
          }
        })
        .catch((err) => {
          const error = err as AxiosError;
          setSuccessPassword(SuccessPasswordType.PASSWORD_RESET_ERROR);
          const msg = error.response
            ? (error.response.data as string)
            : 'Ha ocurrido un error inesperado. Por favor intente mas tarde.';
          setErrorMessage(msg);
        })
        .finally(() => setLoading(false));
    },
  });

  if (loading) {
    return <AccountLoader isInspector={false} />;
  }

  if (successPassword === SuccessPasswordType.PASSWORD_RESET_OK) {
    return (
      <>
        <Typography
          color="textPrimary"
          variant="h4"
          marginBottom={3}
        >
          ¡Contraseña Enviada!
        </Typography>
        <CheckCircleRoundedIcon
          color="primary"
          style={iconStyles}
        />
        <Typography
          color="textPrimary"
          variant="body1"
          marginTop={4}
          marginX="auto"
        >
          {inspectorDTEmail.test(formik.values.userName.toLowerCase())
            ? `Su nueva contraseña ha sido enviada a ${formik.values.userName.toLowerCase()}`
            : 'Su nueva contraseña ha sido enviada a su correo electrónico.'}
        </Typography>
        <Button
          color="primary"
          fullWidth
          size="large"
          type="button"
          variant="contained"
          onClick={handleClose}
          sx={{ marginTop: 2 }}
        >
          Iniciar Sesión
        </Button>
      </>
    );
  }

  if (
    successPassword === SuccessPasswordType.PASSWORD_RESET_ERROR &&
    errorMessage
  ) {
    return <ErrorMessage errorMessage={errorMessage} />;
  }

  return (
    <>
      <>
        <Typography
          color="textPrimary"
          variant="h4"
          marginBottom={3}
        >
          Solicitud
        </Typography>
        <Typography
          color="textPrimary"
          id="modal-modal-title"
          variant="body1"
        >
          Para recibir una nueva contraseña en su correo electrónico sólo debe
          ingresar su usuario con el que accede a la plataforma:
        </Typography>
      </>

      <form
        onSubmit={formik.handleSubmit}
        style={{
          position: 'relative',
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          autoFocus
          fullWidth
          label="Usuario"
          margin="normal"
          name="userName"
          onChange={formik.handleChange}
          type="text"
          variant="outlined"
          autoComplete="off"
          value={formik.values.userName}
        />
        {formik.touched.userName && formik.errors.userName && (
          <Typography
            color="error"
            variant="caption"
            marginBottom={3}
            sx={{
              position: 'absolute',
              left: '5px',
              top: '75px',
              animation: `${animationPulse} 0.5s ease`,
            }}
          >
            {formik.errors.userName}
          </Typography>
        )}
        <Button
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          // loading={loading}
          sx={{
            marginTop: '18px',
          }}
        >
          Enviar Contraseña
        </Button>
      </form>
    </>
  );
};

export default UserForm;
