import client from 'api/client';
import { AxiosResponse } from 'axios';
import { InspectorLogin } from 'types/accountFlow';

export const createUpdateInspectorAccount = (
  inspectorEmail: string
): Promise<AxiosResponse<InspectorLogin>> => {
  return client.post<InspectorLogin>(
    `/Login/InspectorLogin?email=${inspectorEmail}`
  );
};

export default createUpdateInspectorAccount;
