import { useState } from 'react';
import {
  Box,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { updateDriverAttendanceObservation } from 'src/api/routeSheetRequest';
import { RouteSheetResource } from 'src/types/routeSheet';
import { DriverObservation, ObservationType } from 'src/types/filters';
import { formatTime } from 'src/utils/datetimeHelper';
import { AttendanceType } from 'src/types/assistance';
import Item from 'src/pages/routeSheet/components/Item';
import ButtonComment from 'src/pages/routeSheet/components/ButtonComment';

interface CardAttendanceProps {
  assistance: RouteSheetResource;
  maxCharacter: number;
}
const CardAttendance = ({ assistance, maxCharacter }: CardAttendanceProps) => {
  const theme = useTheme();
  const [observationValue, setObservationValue] = useState<string>(
    assistance.driverObservations ?? ''
  );
  const [loading, setLoading] = useState(false);

  const isSmDisplay = useMediaQuery(theme.breakpoints.down('md'));

  const onClick = () => {
    setLoading(true);
    const param: DriverObservation = {
      rut: assistance.rut,
      ObservationType: ObservationType.Attendance,
      attendanceDate: assistance.attendanceStart ?? new Date(),
      id: Number(assistance.id),
      observation: observationValue,
    };
    updateDriverAttendanceObservation(param)
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObservationValue(event.target.value);
  };

  const validateDisable = () => {
    if (assistance.attendanceStart === null) return true;
    if (assistance.dispatches?.length === 0) return true;
    if (observationValue.trim().length === 0) return true;
    if (observationValue.trim().length <= 5) return true;
    if (observationValue.trim().length > maxCharacter) return true;
    if (observationValue.trim() === assistance.driverObservations?.trim())
      return true;
    return false;
  };

  return (
    <Box>
      <Item
        sx={{
          boxShadow: 'none',
        }}
      >
        <Stack
          direction={isSmDisplay ? 'column-reverse' : 'row'}
          spacing={2}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="column"
            justifyContent={'flex-start'}
            flexGrow={1}
          >
            <TextField
              id="outlined-multiline-static"
              label="Observación General"
              multiline
              fullWidth
              rows={5}
              onChange={handleInputChange}
              color={
                observationValue.trim() !==
                assistance.driverObservations?.trim()
                  ? 'warning'
                  : 'primary'
              }
              value={observationValue}
              helperText={
                <Box
                  component="span"
                  sx={{
                    display: 'flex',
                    justifyContent:
                      (observationValue.length <= 5 &&
                        observationValue.length > 0) ||
                      observationValue.length > maxCharacter
                        ? 'space-between'
                        : 'flex-end',
                    color:
                      (observationValue.length <= 5 &&
                        observationValue.length > 0) ||
                      observationValue.length > maxCharacter
                        ? 'red'
                        : theme.palette.text.primary,
                  }}
                >
                  {observationValue.length <= 5 &&
                    observationValue.length > 0 && (
                      <span>Mínimo 5 caracteres</span>
                    )}
                  {observationValue.length > maxCharacter && (
                    <span>Excede el máximo de caracteres</span>
                  )}

                  <span>{`${observationValue.length} / ${maxCharacter}`}</span>
                </Box>
              }
            />
          </Stack>
          <Stack
            aria-labelledby="category-assistance"
            sx={{ p: 1, width: isSmDisplay ? 280 : 165 }}
          >
            {assistance.attendanceType === AttendanceType.Worked ||
            assistance.attendanceType === AttendanceType.ExtraShift ? (
              <>
                <Typography
                  variant="inherit"
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <strong>Hora Programada: </strong>
                  {assistance.scheduleText !== null
                    ? assistance.scheduleText
                    : ''}
                </Typography>

                <Typography
                  variant="inherit"
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <strong>Hora Entrada: </strong>
                  {assistance.attendanceStartText &&
                    assistance.attendanceStartText}
                </Typography>
                <Typography
                  variant="inherit"
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <strong>Hora Salida: </strong>
                  {assistance.attendanceEnd
                    ? formatTime(assistance.attendanceEnd)
                    : ''}
                </Typography>
              </>
            ) : (
              <Typography
                variant="inherit"
                sx={{
                  textAlign: 'left',
                }}
              >
                {assistance.attendanceTypeName}
              </Typography>
            )}

            {!isSmDisplay && (
              <ButtonComment
                loading={loading}
                disabled={validateDisable()}
                observations={assistance.driverObservations}
                onClick={onClick}
              />
            )}
          </Stack>
        </Stack>
        {isSmDisplay && (
          <ButtonComment
            loading={loading}
            disabled={validateDisable()}
            observations={assistance.driverObservations}
            onClick={onClick}
          />
        )}
      </Item>
    </Box>
  );
};

export default CardAttendance;
