import { FC, useEffect } from 'react';
import { Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Content from './SidebarContent';

interface DefaultSidebarProps {
  onClose: () => void;
  open: boolean;
}

const DefaultSidebar: FC<DefaultSidebarProps> = (props) => {
  const { onClose, open } = props;
  const location = useLocation();
  useEffect(() => {
    if (open) {
      onClose();
    }
  }, [location.pathname]);
  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px) !important',
          top: '64px !Important',
          width: 280,
        },
      }}
      variant="temporary"
    >
      <Content />
    </Drawer>
  );
};

export default DefaultSidebar;
