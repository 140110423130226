import { AxiosError } from 'axios';
import { SnackbarUtils } from '../utils/snackbarUtilsConfigurator';

export const httpErrorNotification = (error: AxiosError | string) => {
  const codeMatcher: Record<string, string> = {
    ERR_NETWORK: 'Error de red',
    ERR_TIMEOUT: 'Expiró el tiempo de la petición',
    ERR_UNKNOWN: 'Error desconocido',
    ERR_BAD_REQUEST: 'La petición es erronea',
    ERR_500: 'Ocurrió un error en el servidor',
  };

  if (error instanceof AxiosError) {
    /*
    if (error.code && codeMatcher[error.code])
      SnackbarUtils.error(codeMatcher[error.code]);
    */
    throw error;
  } else if (typeof error === 'string') SnackbarUtils.error(codeMatcher[error]);
  else SnackbarUtils.error(codeMatcher.ERR_UNKNOWN);
  throw new Error(error);
};
