import { OvertimeDealPeriod, SignStatus } from 'src/pages/deals/types/drivers';
import { IncidentLevel } from './incidentReport';
import { Shift } from './costCentre';

export interface IFilters {
  startDate: Date;
  endDate: Date;
  ruts: string[];
  terminals: number[];
  shifts: Shift[];
  includeSettled: boolean;
  status: SignStatus | null;
}

export interface IDealFilters {
  ruts: string[];
  rol: number;
  isActive: boolean;
}

export enum LimitType {
  ExcedeMaximo,
  Ok,
}
export interface ILimitSearch {
  type: LimitType;
  message: string;
}

export interface FilterDailyControl {
  date: Date;
  route: string;
  onlyActive: boolean;
}

export interface DriverObservation {
  id: number;
  rut: string;
  ObservationType: ObservationType;
  attendanceDate: Date;
  observation: string;
}

export enum ObservationType {
  Dispatch,
  Attendance,
}

export interface FilterOption {
  id: string;
  name: string;
}

export interface OvertimeDealInput {
  ruts: string[];
  period: OvertimeDealPeriod;
}

export interface SignOvertimeDealInput {
  id: string;
  sign: boolean;
}
export interface IIncidentsFilters {
  startDate: Date;
  endDate: Date;
  level?: IncidentLevel;
}
