import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import useAuth from 'hooks/useAuth';

interface VersionDisplayerProps {
  horizontal: boolean;
}

const VersionDisplayer = ({ horizontal = true }: VersionDisplayerProps) => {
  const { version } = useAuth();
  return (
    <Box
      marginBottom={horizontal ? 0 : 1}
      sx={{
        display: 'flex',
        flexDirection: horizontal ? 'row' : 'column',
        gap: horizontal ? '0.4rem' : 0,
        justifyContent: 'center',
        padding: horizontal ? 0 : '1rem 0px 0px 1rem',
        '@media screen and (min-width: 600px)': {
          marginTop: horizontal ? '1rem' : 0,
        },
        '@media screen and (max-width: 320px)': {
          flexDirection: 'column',
          alignItems: horizontal ? 'center' : 'normal',
        },
      }}
    >
      <Typography
        color="textSecondary"
        variant="body2"
        sx={{
          cursor: 'default',
        }}
      >
        Metbus &copy; PlanniDev 2022
      </Typography>
      {version && (
        <Typography
          color="textSecondary"
          variant="body2"
          sx={{
            cursor: 'default',
          }}
        >
          Versión
          {` ${version}`}
        </Typography>
      )}
    </Box>
  );
};

export default VersionDisplayer;
