import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import RouterBreadcrums from 'components/shared/RouterBreadcrumbs';
import DefaultSidebar from 'components/shared/DefaultSidebar';
import { Box, styled } from '@mui/material';
import ScrollToTop from 'pages/shared/ScrollToTop';
import DashboardNavbar from './DashboardNavbar';
import usePath from '../../components/shared/usePath';
import FloatingComment from 'src/pages/shared/FloatingComment';
import FloatingCommentWithModal from 'src/pages/shared/FloatingCommentWithModal';
import useAuth from 'src/hooks/useAuth';
import { AxiosConfigurator } from 'src/utils/axiosConfigurator';
import { UserProfiles } from 'src/types/userProfiles';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  overflow: 'auto',
  height: '100vh',
  width: '100vw',
  maxWidth: '100% !important',
  paddingX: '0px !important',
}));

interface LayoutWrapperI {
  open: boolean;
  onHome: boolean;
}
const DashboardLayoutWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'onHome',
  name: 'DashboardLayoutWrapper',
  slot: 'Wrapper',
})<LayoutWrapperI>(({ theme, open, onHome }) => ({
  backgroundColor: theme.palette.background.default,
  overflow: 'auto',
  paddingBottom: '20px',
  paddingTop: onHome ? '0px' : '50px',
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const { user } = useAuth();
  const stateHandler = () => setIsSidebarOpen(!isSidebarOpen);
  const { pathnames, breadcrumbNameMap, isPath } = usePath();
  const permission = user?.profile === UserProfiles.Admin;
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={stateHandler} />
      <DefaultSidebar
        onClose={stateHandler}
        open={isSidebarOpen}
      />
      <DashboardLayoutWrapper
        open={isSidebarOpen}
        onHome={!isPath}
      >
        <AxiosConfigurator />
        <Box
          px={2}
          py={2}
          sx={{ display: { xs: 'none', lg: 'block' } }}
        >
          {!!isPath && (
            <RouterBreadcrums
              pathnames={pathnames}
              breadcrumbNameMap={breadcrumbNameMap}
            />
          )}
        </Box>
        <>
          <Outlet />
          <ScrollToTop />
          {user?.profile === UserProfiles.Conductor &&
            isMobile &&
            permission && <FloatingComment isPath={isPath} />}
          {user?.profile === UserProfiles.Conductor &&
            !isMobile &&
            permission && <FloatingCommentWithModal />}
        </>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
