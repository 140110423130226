import { Backdrop } from '@mui/material';
import Rive from '@rive-app/react-canvas';

const MetbusLoadingRive = () => {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open
    >
      <Rive
        src="/metbus.riv"
        style={{ height: '800px' }}
      />
    </Backdrop>
  );
};

export default MetbusLoadingRive;
