import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import NewPasswordModal from 'src/components/authentication/forgottenPassword/NewPasswordModal';
import { LoginJWT } from 'src/components/authentication/login';

interface CardLoginProps {
  children: React.ReactNode;
}
const CardLogin = ({ children }: CardLoginProps) => {
  return (
    <Card
      sx={{
        width: '350px',
        height: 'auto',
        boxShadow: 1,
        transition: 'all 400ms ease',
        '&:hover': {
          transition: 'all 400ms ease-in',
          boxShadow: 2,
        },
        '@media screen and (max-width: 374px)': {
          width: '260px',
        },
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 4,
          gap: '1rem',
        }}
      >
        <Typography
          color="textPrimary"
          variant="h4"
        >
          Inicio de Sesión
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            mt: 3,
          }}
        >
          <LoginJWT />
        </Box>
        <NewPasswordModal />
        {React.Children.map(children, (child) => child)}
      </CardContent>
    </Card>
  );
};

export default CardLogin;
