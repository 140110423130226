import { Box, Typography, Divider, Avatar } from '@mui/material';
import NavSection from 'components/NavSection';
import Scrollbar from 'components/Scrollbar';
import { useLocation } from 'react-router-dom';
import { capitalizeString, splitName, stringToColor } from 'utils/stringHelper';
// import sections from './NavSectionsNames';
import useAuth from '../../hooks/useAuth';
// import { UserProfiles } from '../../types/userProfiles';
import VersionDisplayer from './VersionDisplayer';
import getSections from './NavSectionsNames';

const Content = () => {
  const { user } = useAuth();
  const location = useLocation();
  const filter = user ? getSections(user.profile, user.rut) : [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'space-between',
        overflow: 'hidden',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              p: 2,
            }}
          >
            <Avatar
              sx={{
                cursor: 'pointer',
                height: 48,
                width: 48,
                bgcolor: stringToColor(capitalizeString(user?.name ?? '')),
              }}
            >
              {splitName(capitalizeString(user?.name ?? ''))}
            </Avatar>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {capitalizeString(user?.name ?? '')}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {user?.profile}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {filter.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
      <VersionDisplayer horizontal={false} />
    </Box>
  );
};

export default Content;
