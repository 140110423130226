import { useLocation } from 'react-router-dom';
import { NameMap as breadcrumbNameMap } from './NavSectionsNames';

const usePath = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const isPath = breadcrumbNameMap[location.pathname];
  // TODO: No estoy seguro en la validacion del Record si existe el path
  if (!breadcrumbNameMap.hasOwnProperty(location.pathname)) {
    pathnames.pop();
  }

  return { pathnames, breadcrumbNameMap, isPath };
};

export default usePath;
