import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import animationPulse from 'components/css/animationPulse';
import { useFormik } from 'formik';
import { useState } from 'react';
import createUpdateInspectorAccount from 'src/api/createUpdateInspectorAccount';
import AccountLoader from 'src/components/authentication/inspectorAccount/AccountLoader';
import iconStyles from 'src/components/css/iconStyle';
import LogoDT from 'src/icons/LogoDT';
import { InspectorLogin, UserInspectorStatus } from 'src/types/accountFlow';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import * as Yup from 'yup';
import { formatDate } from 'src/utils/datetimeHelper';
import ErrorMessage from 'src/components/authentication/ErrorMessage';

interface InspectorFormProps {
  handleClose: () => void;
  handleSendPassword: (email: string) => void;
}
const InspectorForm = ({
  handleClose,
  handleSendPassword,
}: InspectorFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [inspectorState, setInspectorState] = useState<InspectorLogin | null>(
    null
  );
  const emailValidation = /^[a-z\d]+(\.[a-z\d]+|_[a-z\d]+)*$/gi;
  const specialCharacters = /[^a-zA-Z\d\s/._]/gi;
  const incorrectStartOrEnd = /^[^a-z\d]|[^a-z\d]$/gi;
  const whiteSpaces = /\s+/g;

  const formik = useFormik({
    initialValues: {
      inspectorEmail: '',
    },
    validationSchema: Yup.object({
      inspectorEmail: Yup.string()
        .required('Debe ingresar su usuario')
        .test({
          name: 'is-inspector-email',
          test(value, context) {
            if (value == undefined) {
              return context.createError({
                message: 'Ingrese el usuario',
              });
            }
            if (whiteSpaces.test(value)) {
              return context.createError({
                message: 'No ingrese espacios en blanco',
              });
            }
            if (specialCharacters.test(value)) {
              return context.createError({
                message: 'No ingrese caracteres especiales',
              });
            }
            if (incorrectStartOrEnd.test(value)) {
              return context.createError({
                message: 'No debe empezar o terminar con caracteres especiales',
              });
            }
            if (!emailValidation.test(value)) {
              return context.createError({
                message: 'El usuario no es válido',
              });
            }
            return true;
          },
        }),
    }),
    onSubmit: ({ inspectorEmail }) => {
      setLoading(true);
      createUpdateInspectorAccount(`${inspectorEmail.toLowerCase()}@dt.gob.cl`)
        .then((res) => {
          if (res.status === 200) {
            setInspectorState(res.data);
          }
        })
        .catch((err) => {
          setInspectorState({
            email: inspectorEmail.toLowerCase(),
            status: UserInspectorStatus.ERROR,
            expirationDate: null,
          });
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => setLoading(false));
    },
  });

  if (loading) {
    return <AccountLoader isInspector />;
  }

  if (inspectorState?.status === UserInspectorStatus.ERROR) {
    return (
      <ErrorMessage errorMessage="Ha ocurrido un error inesperado. Por favor intente nuevamente.">
        <Button
          color="primary"
          fullWidth
          size="large"
          type="button"
          variant="contained"
          onClick={formik.handleChange}
          sx={{ marginTop: '16px' }}
        >
          Reintentar
        </Button>
      </ErrorMessage>
    );
  }

  if (
    inspectorState?.status === UserInspectorStatus.SUCCESSFULLY_ACTIVATED &&
    inspectorState.email !== undefined
  ) {
    return (
      <>
        <Typography
          color="textPrimary"
          variant="h4"
          marginBottom={3}
        >
          ¡Cuenta Activada!
        </Typography>
        <CheckCircleRoundedIcon
          color="primary"
          style={iconStyles}
        />
        <Typography>
          <Typography
            color="textPrimary"
            variant="body1"
            marginTop={3}
            marginX="auto"
          >
            {`Su contraseña ha sido enviada a ${inspectorState.email.toLowerCase()}`}
          </Typography>
          <Typography
            color="textPrimary"
            variant="body1"
            marginTop={2}
            marginX="auto"
            sx={{ width: '100%' }}
          >
            Esta expirará automáticamente el día
            <span
              style={{
                fontWeight: 500,
                display: 'inline-block',
              }}
            >
              {inspectorState.expirationDate !== null
                ? formatDate(inspectorState.expirationDate)
                : ''}
            </span>
          </Typography>
        </Typography>
        <Button
          color="primary"
          fullWidth
          size="large"
          type="button"
          variant="contained"
          onClick={handleClose}
          sx={{ marginTop: 2 }}
        >
          Iniciar Sesión
        </Button>
      </>
    );
  }

  if (inspectorState?.status === UserInspectorStatus.ALREADY_ACTIVATED) {
    // resert password
    return (
      <>
        <Typography>
          <Typography
            color="textPrimary"
            variant="h4"
            marginBottom={5}
          >
            Contraseña Activa
          </Typography>
          <Typography
            color="textPrimary"
            variant="body1"
            marginTop={2}
            marginX="auto"
          >
            Su contraseña actual se encuentra activa hasta el día
            <span
              style={{
                fontWeight: 500,
                display: 'inline-block',
                paddingLeft: '5px',
              }}
            >
              {inspectorState.expirationDate === null
                ? ''
                : formatDate(inspectorState.expirationDate)}
            </span>
          </Typography>
        </Typography>
        <Typography
          color="textPrimary"
          variant="body1"
          marginY={2}
          marginX="auto"
          textAlign="center"
          fontWeight={500}
        >
          ¿Desea recibir una nueva contraseña a su correo electrónico?
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <Button
            color="error"
            fullWidth
            size="large"
            type="button"
            variant="outlined"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            fullWidth
            size="large"
            onClick={() => handleSendPassword(inspectorState.email ?? '')}
            // loading={loading}
            variant="contained"
          >
            Enviar
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <Typography
        color="textPrimary"
        variant="h4"
        marginBottom={3}
      >
        Acceso Inspector
      </Typography>
      <LogoDT style={iconStyles} />
      <form
        onSubmit={formik.handleSubmit}
        style={{
          position: 'relative',
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          autoFocus
          fullWidth
          label="Usuario"
          margin="normal"
          name="inspectorEmail"
          onChange={formik.handleChange}
          type="text"
          variant="outlined"
          autoComplete="off"
          value={formik.values.inspectorEmail}
          InputProps={{
            endAdornment: <Typography>@dt.gob.cl</Typography>,
          }}
        />
        {formik.touched.inspectorEmail && formik.errors.inspectorEmail && (
          <Typography
            color="error"
            id="inspector-input-error"
            variant="caption"
            marginBottom={3}
            sx={{
              position: 'absolute',
              left: '5px',
              top: '75px',
              animation: `${animationPulse} 0.5s ease`,
            }}
          >
            {formik.errors.inspectorEmail}
          </Typography>
        )}
        <Button
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            marginTop: '18px',
          }}
        >
          Enviar Contraseña
        </Button>
      </form>
    </>
  );
};
export default InspectorForm;
