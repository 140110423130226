import { styled } from '@mui/material';
import { FC } from 'react';

interface DecorativePatternProps {
  style?: React.CSSProperties;
}
const DecorativeRoot = styled('svg')({});

const DecorativePattern: FC<DecorativePatternProps> = (
  props: DecorativePatternProps
) => {
  const { style } = props;
  return (
    <DecorativeRoot
      viewBox="0 0 161.8 161.8"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={style}
    >
      <path
        fill="background.default"
        d="M33.8 161.8h-1.4L0 129.4V128z"
      />
      <path
        fill="#0093B3"
        d="m0 129.4 32.4 32.4H0zM151 161.8H33.8L0 128V10.8l38.4 38.4.3.3zM161.8 161.8h-1.4L0 1.4V0z"
      />
    </DecorativeRoot>
  );
};

export default DecorativePattern;
