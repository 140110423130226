import { Box, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const RouterBreadcrumbs = ({
  pathnames,
  breadcrumbNameMap,
}: {
  pathnames: string[];
  breadcrumbNameMap: Record<string, string>;
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const path = [...pathnames].slice(-1);
  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        {path.map(() => {
          const to = `/${pathnames.join('/')}`;

          return (
            <Typography
              color={matches ? 'text.primary' : 'primary.contrastText'}
              fontWeight="600"
              key={to}
            >
              {breadcrumbNameMap[to]}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default RouterBreadcrumbs;
