export const mockAndDevelopment = () =>
  import.meta.env.DEV && import.meta.env.VITE_APP_USE_MOCK === 'true';

export const productive = () => import.meta.env.PROD;

export const apiGoComplianceAppURL = () => import.meta.env.VITE_APP_API_URL;

export const reduxDevTools = () =>
  import.meta.env.VITE_APP_ENABLE_REDUX_DEV_TOOLS === 'true';

export const apiUserManagerURL = () => import.meta.env.VITE_USER_MANAGER;

export const apiMailingURL = () => import.meta.env.VITE_API_MAILING;

export const systemId = 'GO-COMPLIANCE-WEB';
