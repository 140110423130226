import BasicModal from 'components/shared/BasicModal';
import InspectorForm from 'src/components/authentication/inspectorAccount/InspectorForm';
import { useState } from 'react';
import { Button } from '@mui/material';
import UserForm from 'src/components/authentication/forgottenPassword/UserForm';

const InspectorModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [resetPasswordState, setResetPasswordState] = useState<string>('');
  const handleOpen = () => setOpen(true);
  const handleClose = (): void => {
    setResetPasswordState('');
    setOpen(false);
  };

  const handleSendPassword = (email: string) => {
    setResetPasswordState(email);
  };
  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        color="primary"
        size="large"
        sx={{
          width: '90%',
          marginInline: 'auto',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '@media screen and (max-width: 330px)': {
            fontSize: '12px',
          },
          '&:hover': {
            color: '#0093b3',
            transition: 'all 0.3s ease',
          },
        }}
      >
        Solicitar acceso Inspector DT
      </Button>
      {open && (
        <BasicModal
          open={open}
          handleClose={handleClose}
        >
          {resetPasswordState !== '' ? (
            <UserForm
              email={resetPasswordState}
              handleClose={handleClose}
            />
          ) : (
            <InspectorForm
              handleClose={handleClose}
              handleSendPassword={handleSendPassword}
            />
          )}
        </BasicModal>
      )}
    </>
  );
};

export default InspectorModal;
