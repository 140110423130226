import { FC, useState } from 'react';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Button,
} from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import type { AppBarProps } from '@mui/material';
import MetbusLogo from 'components/MetbusLogo';
import MenuIcon from 'icons/Menu';
import AccountPopover from 'components/shared/AccountPopover';
import useSettings from 'hooks/useSettings';
import RouterBreadcrums from 'components/shared/RouterBreadcrumbs';
import { THEMES } from '../../constants';
import usePath from '../../components/shared/usePath';
import useAuth from '../../hooks/useAuth';
import { UserProfiles } from '../../types/userProfiles';
import ThemeUISwitch from 'src/pages/shared/ThemeUISwitch';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  height: '64px',
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarOpen, ...other } = props;
  const { settings, saveSettings } = useSettings();
  const { theme } = settings;
  const [checked, setChecked] = useState(theme === THEMES.METBUSDARK);
  const location = useLocation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      saveSettings({ ...settings, theme: THEMES.METBUSDARK });
    } else {
      saveSettings({ ...settings, theme: THEMES.METBUSLIGHT });
    }
    setChecked(event.target.checked);
  };
  const { user } = useAuth();
  const { pathnames, breadcrumbNameMap, isPath } = usePath();

  const linkTo =
    user?.profile === UserProfiles.Inspector ? '/inspectorDT' : '/';
  const shouldHideButton = location.pathname === '/inspectorDT';
  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          {!shouldHideButton && (
            <IconButton
              color="inherit"
              onClick={onSidebarOpen}
            >
              <MenuIcon
                fontSize="small"
                sx={{ mr: 1 }}
              />
            </IconButton>
          )}
          <Box
            px={2}
            py={2}
          >
            {!!isPath && (
              <RouterBreadcrums
                pathnames={pathnames}
                breadcrumbNameMap={breadcrumbNameMap}
              />
            )}
          </Box>
        </Hidden>
        <Hidden lgDown>
          {!shouldHideButton && (
            <IconButton
              color="inherit"
              onClick={onSidebarOpen}
            >
              <MenuIcon
                fontSize="small"
                sx={{ mr: 1 }}
              />
            </IconButton>
          )}
          <RouterLink to={linkTo}>
            <MetbusLogo
              height="50"
              width="120"
            />
          </RouterLink>
        </Hidden>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {user?.profile === UserProfiles.Inspector ? (
          <Box sx={{ ml: 1 }}>
            <Button
              component={RouterLink}
              to="/inspectorDT"
              color="inherit"
              variant="text"
              sx={{
                border: '1px solid white',
                borderRadius: '4px',
                padding: '8px 16px',
              }}
            >
              Inspector D.T
            </Button>
          </Box>
        ) : null}

        <Box sx={{ ml: 1 }}>
          <ThemeUISwitch
            sx={{ m: 1 }}
            checked={checked}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
