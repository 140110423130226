import { Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import iconStyles from 'src/components/css/iconStyle';
import React from 'react';

interface ErrorMessageProps {
  errorMessage: string;
}
const ErrorMessage = ({
  errorMessage,
  children,
}: React.PropsWithChildren<ErrorMessageProps>) => {
  return (
    <>
      <Typography
        color="textPrimary"
        variant="h4"
        marginBottom={3}
      >
        Lo sentimos
      </Typography>
      <ErrorIcon
        color="error"
        style={iconStyles}
      />
      <Typography
        color="textPrimary"
        variant="body1"
        marginTop={4}
        marginX="auto"
      >
        {errorMessage}
      </Typography>
      {React.Children.map(children, (child) => child)}
    </>
  );
};

export default ErrorMessage;
