import { FC, ReactNode } from 'react';
import useAuth from 'hooks/useAuth';
import { useLocation, Navigate } from 'react-router-dom';
import { UserProfiles } from 'types/userProfiles';

interface RequireAuthProps {
  allowedProfiles: UserProfiles[];
  children: ReactNode;
}
const RequireAuth: FC<RequireAuthProps> = (props) => {
  const { allowedProfiles, children } = props;
  const { user } = useAuth();
  const location = useLocation();

  return allowedProfiles.find((role) => user?.profile.includes(role)) ? (
    <>{children}</>
  ) : (
    <Navigate
      to="/401"
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
