import { Keyframes } from '@emotion/react';
import { keyframes } from '@mui/system';

const animationPulse: Keyframes = keyframes`
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.2);
}
100% {
  transform: scale(1);
}`;

export default animationPulse;
