import { IdentityClaims, IUser } from 'src/types/loginResponse';
import { UserProfiles } from 'src/types/userProfiles';

export const getUserIdentity = (
  identity: IdentityClaims,
  profile: UserProfiles
) => {
  const { userName, name, email, rut, rol } = identity;

  return {
    userName,
    name,
    email,
    profile,
    rut,
    rol: Number(rol),
  } as IUser;
};
