import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { mockAndDevelopment } from 'src/app.config';
import useAuth from '../../../hooks/useAuth';
import { getErrorMessage } from 'src/utils/errorHelper';
import { SnackbarUtils } from 'src/utils/snackbarUtilsConfigurator';

const LoginJWT: FC = (props) => {
  const { login, isAuthenticated } = useAuth();

  return (
    <Formik
      initialValues={{
        username: mockAndDevelopment() ? 'demo@devias.io' : '',
        password: mockAndDevelopment() ? 'Password123!' : '',
        showPassword: false,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(255).required('Se debe ingresar usuario'),
        password: Yup.string().max(255).required('Se debe ingresar contraseña'),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.username, values.password);

          if (isAuthenticated) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: getErrorMessage(err) });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setValues,
      }): JSX.Element => {
        const handleClickShowPassword = () => {
          setValues({
            ...values,
            showPassword: !values.showPassword,
          }).catch((err) => SnackbarUtils.error(getErrorMessage(err)));
        };

        const handleMouseDownPassword = (
          event: React.MouseEvent<HTMLButtonElement>
        ) => {
          event.preventDefault();
        };
        return (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            <TextField
              autoFocus
              error={Boolean(touched.username && errors.username)}
              fullWidth
              helperText={touched.username && errors.username}
              label="Usuario"
              margin="normal"
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              type="string"
              value={values.username}
              variant="outlined"
              autoComplete="off"
            />
            <FormControl
              variant="outlined"
              margin="normal"
              fullWidth
            >
              <InputLabel
                error={Boolean(touched.password && errors.password)}
                htmlFor="outlined-adornment-password"
              >
                Contraseña
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                error={Boolean(touched.password && errors.password)}
                label="Contraseña"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      aria-errormessage="password-error-text"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                id="password-error-text"
                error={Boolean(touched.password && errors.password)}
              >
                {touched.password && errors.password}
              </FormHelperText>
            </FormControl>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <Alert
                  variant="filled"
                  severity="error"
                >
                  {errors.submit}
                </Alert>
              </Box>
            )}
            <Box
              sx={{
                mt: 2,
                marginInline: 'auto',
                width: '90%',
              }}
            >
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Iniciar Sesión
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default LoginJWT;
