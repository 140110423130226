import { getDriversWithDeals } from 'src/api/drivers';
import { DriversWithDealsResource } from 'src/pages/deals/types/drivers';
import { create } from 'src/store/createStore';
import { IDealFilters } from 'src/types/filters';
import { getErrorMessage } from 'src/utils/errorHelper';
import { SnackbarUtils } from 'src/utils/snackbarUtilsConfigurator';

interface DealData {
  filter: IDealFilters;
  isLoading: boolean;
  driverWithDeals: DriversWithDealsResource | null;
  tabIndex: number;
  setTabIndex: (value: number) => void;
  setLoading: (value: boolean) => void;
  getData: (filter: IDealFilters) => void;
  setDriverWithDeals: (value: DriversWithDealsResource | null) => void;
  updateData: () => void;
}

export const dealDataStore = create<DealData>()((set, get) => ({
  driverWithDeals: null,
  filter: {
    ruts: [],
    rol: 0,
    isActive: true,
  },
  tabIndex: 0,
  setTabIndex: (value: number) => set({ tabIndex: value }),
  setDriverWithDeals: (value: DriversWithDealsResource | null) =>
    set({ driverWithDeals: value }),
  getData: (filter: IDealFilters) => {
    set({ isLoading: true });
    set({ filter: filter });
    getDriversWithDeals(filter)
      .then((response) => {
        if (response.status === 200 && response.data !== null) {
          set({ driverWithDeals: response.data });
        } else set({ driverWithDeals: null });
      })
      .catch((err) => {
        set({ driverWithDeals: null });
        SnackbarUtils.error(getErrorMessage(err));
      })
      .finally(() => {
        set({ isLoading: false });
      });
  },
  isLoading: false,
  setLoading: (value: boolean) => set({ isLoading: value }),
  updateData: () => {
    get().getData(get().filter);
  },
}));
