import { Backdrop, Box, Modal } from '@mui/material';
import React from 'react';

interface ModalCustomProps {
  open: boolean;
  width: string;
  handleClose: () => void;
}
const ModalCustom = ({
  open,
  width,
  children,
  handleClose,
}: React.PropsWithChildren<ModalCustomProps>) => {
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width,
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>{React.Children.map(children, (child) => child)}</Box>
    </Modal>
  );
};

export default ModalCustom;
