import axios, { AxiosInstance } from 'axios';
import { apiMailingURL, mockAndDevelopment, productive } from 'src/app.config';

const mailingClient: AxiosInstance =
  productive() && mockAndDevelopment()
    ? axios.create()
    : axios.create({
        baseURL: apiMailingURL(),
        headers: {
          accept: '*/*',
          contentType: 'application/json',
        },
        paramsSerializer: {
          indexes: null,
        },
      });

export const sendInspectorAccessMail = () => {
  return mailingClient.post<string>('api/CGOMailSender/InspectorAccessMail');
};

export default mailingClient;
