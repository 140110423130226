import client from 'api/client';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'src/utils/errorHelper';
import { SnackbarUtils } from 'src/utils/snackbarUtilsConfigurator';
import { RouteSheetResource } from 'types/routeSheet';
import { DriverObservation, IFilters } from 'types/filters';
import { formatDateApi } from 'utils/datetimeHelper';

export const getRouteSheet = (
  param: IFilters
): Promise<AxiosResponse<RouteSheetResource[]>> =>
  client.post<RouteSheetResource[]>('/api/RouteSheet', {
    startDate: formatDateApi(new Date(param.startDate)),
    endDate: formatDateApi(new Date(param.endDate)),
    ruts: param.ruts,
    shiftNumbers: param.shifts.map((a) => a.id),
  });

export const updateDriverDispatchObservation = async (
  param: DriverObservation
) => {
  try {
    const response = await client.post<RouteSheetResource[]>(
      '/DriverObservation/UpdateDriverDispatchObservation',
      param
    );
    if (response.status) SnackbarUtils.success('Se ingreso exitosamente');
  } catch (error) {
    SnackbarUtils.error(getErrorMessage(error));
  }
};

export const updateDriverAttendanceObservation = async (
  param: DriverObservation
) => {
  try {
    const response = await client.post<RouteSheetResource[]>(
      '/DriverObservation/UpdateDriverAttendanceObservation',
      param
    );

    if (response.status) SnackbarUtils.success('Se ingreso exitosamente');
  } catch (error) {
    SnackbarUtils.error(getErrorMessage(error));
  }
};
