import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { BasicModalProps } from 'types/modal';
import animationPulse from '../css/animationPulse';
import React from 'react';

const BasicModal = ({ open, handleClose, children }: BasicModalProps) => (
  <Modal
    open={open}
    onClose={(_, reason) => {
      if (reason !== 'backdropClick') return handleClose;
      return null;
    }}
    disableAutoFocus
    BackdropProps={{
      transitionDuration: {
        enter: 600,
      },
      sx: {
        backgroundColor: '#0092b3dc',
      },
    }}
  >
    <Container
      maxWidth="xs"
      sx={{
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Card
        sx={{
          padding: '35px',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          position: 'relative',
        }}
      >
        <CancelIcon
          onClick={handleClose}
          sx={{
            position: 'absolute' as const,
            right: '18px',
            top: '18px',
            cursor: 'pointer',
            transition: 'all 0.5s ease',
            '&:hover': {
              color: '#0093b3',
              transition: 'all 0.5s ease',
              animation: `${animationPulse} 0.5s ease`,
            },
          }}
        />
        {React.Children.map(children, (child) => child)}
      </Card>
    </Container>
  </Modal>
);

export default BasicModal;
