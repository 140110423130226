import { Box, Button, CircularProgress } from '@mui/material';

interface LoadingButtonProps {
  id?: string | undefined;
  children?: React.ReactNode;
  onClick: () => void;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning';
  variant: 'text' | 'contained' | 'outlined';
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  loading: boolean;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const LoadingButton = ({
  id,
  children,
  onClick,
  color = 'primary',
  variant = 'contained',
  size = 'small',
  fullWidth,
  loading,
  disabled = false,
  startIcon,
  endIcon,
}: LoadingButtonProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        id={id}
        color={color}
        onClick={onClick}
        variant={variant}
        size={size}
        fullWidth={fullWidth}
        disabled={loading ? true : disabled}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: 'inherit',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
};

export default LoadingButton;
