import client from 'src/api/client';
import userClient from './userClient';
import mailingClient from './mailingClient';

const setAuthorizationToken = (authorizationToken: string | null) => {
  if (authorizationToken) {
    client.defaults.headers.common.Authorization = `Bearer ${authorizationToken}`;
    userClient.defaults.headers.common.Authorization = `Bearer ${authorizationToken}`;
    mailingClient.defaults.headers.common.Authorization = `Bearer ${authorizationToken}`;
  } else {
    delete client.defaults.headers.common.Authorization;
    delete userClient.defaults.headers.common.Authorization;
    delete mailingClient.defaults.headers.common.Authorization;
  }
};

export default setAuthorizationToken;
