export const dateFormat: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
export const simpleDateFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
export const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};
export const localeFormat = 'es-CL';

export function compareDates(a: Date, b: Date) {
  if (new Date(a).getTime() > new Date(b).getTime()) {
    return 1;
  }
  if (new Date(a).getTime() < new Date(b).getTime()) {
    return -1;
  }
  // a debe ser igual b
  return 0;
}

export function dateParser(dateString: string): Date {
  const parser = /(\d{4})-(\d{2})-(\d{2})/;
  const match = dateString.match(parser);
  return new Date(
    Number(match !== null ? match[1].toString() : 0), // year
    Number(match !== null ? match[2].toString() : 0) - 1, // monthIndex
    Number(match !== null ? match[3].toString() : 0) // day
  );
}

export function toStringDate(date: string | Date) {
  return dateParser(date.toString()).toLocaleDateString(localeFormat);
}

export function formatDate(date: Date): string {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [day, month, year].join('-');
}

export function formatUTCDate(date: Date): string {
  const d = new Date(date);
  let month = `${d.getUTCMonth() + 1}`;
  let day = `${d.getUTCDate()}`;
  const year = d.getUTCFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [day, month, year].join('-');
}

export const formatDateApi = (date: Date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [year, month, day].join('-');
};

export const formatMonthYear = (date: Date): string => {
  const d = new Date(date);
  const year = new Intl.DateTimeFormat(localeFormat, {
    year: 'numeric',
  }).format(d);
  const month = new Intl.DateTimeFormat(localeFormat, { month: 'long' }).format(
    d
  );

  return `${month[0].toUpperCase()}${month.slice(1)} ${year} `;
};

export function formatTime(date: Date, withSeconds: boolean | null = null) {
  const d = new Date(date);
  let hours = `${d.getHours()}`;
  let minutes = `${d.getMinutes()}`;

  if (hours.length < 2) hours = `0${hours}`;
  if (minutes.length < 2) minutes = `0${minutes}`;
  if (withSeconds !== null && withSeconds) {
    let seconds = `${d.getSeconds()}`;
    if (seconds.length < 2) seconds = `0${seconds}`;
    return [hours, minutes, seconds].join(':');
  }
  return [hours, minutes].join(':');
}

export function getTotalMinutes(time: string): number {
  // const ss = JSON.stringify(time);
  const timeparsed = time.split(':');
  return Number(timeparsed[0]) * 60 + Number(timeparsed[1]);
}

export function timeParser(time: string | null, withSeconds = false): string {
  if (time === null) return '--:--';

  if (withSeconds) return time;
  const timeparsed = time.split(':');
  return `${timeparsed[0]}:${timeparsed[1]}`;

  // const { days } = time;
  // let { minutes, hours } = time;
  // if (days > 1) hours += days * 24;
  // if (hours < 10) hours = `0${hours}`;
  // if (minutes < 10) minutes = `0${minutes}`;
  // return `${hours}:${minutes}`;
}

export function timeIsZero(time: string | null) {
  if (time === null) return false;
  const timeparsed = time.split(':');
  return Number(timeparsed[0]) === 0 && Number(timeparsed[1]) === 0;
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function formatDateTime(date: Date) {
  const dateStr = [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');
  const timeStr = [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds()),
  ].join(':');
  return `${dateStr}T${timeStr}`;
}

export const startOfMonth = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), 1);

export const endOfMonth = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const formatDateReadable = (date: Date): string => {
  const month = new Intl.DateTimeFormat(localeFormat, { month: 'long' }).format(
    date
  );
  return `${date.getDate()} de ${month} del ${date.getFullYear()}`;
};

export const dateTimeToDate = (date: string) => {
  const parts = date.split('-').map((p) => Number(p));
  return new Date(parts[2], parts[1], parts[0]);
};
