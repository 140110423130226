import axios, { AxiosInstance } from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import {
  apiGoComplianceAppURL,
  mockAndDevelopment,
  productive,
} from 'src/app.config';

const client: AxiosInstance =
  productive() && mockAndDevelopment()
    ? axios.create()
    : axios.create({
        baseURL: apiGoComplianceAppURL(),
        headers: {
          accept: '*/*',
          'content-type': 'application/json',
        },
      });

export const mock =
  process.env.NODE_ENV !== 'production' && mockAndDevelopment()
    ? new AxiosMockAdapter(client, { delayResponse: 5 })
    : new AxiosMockAdapter(axios.create());

export default client;
