import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { descendingComparatorDate } from 'src/utils/comparerHelper';
import { DispatchResource, RouteSheetResource } from 'src/types/routeSheet';
import { formatDate } from 'src/utils/datetimeHelper';
import Scrollbar from 'src/components/Scrollbar';
import { AttendanceType } from 'src/types/assistance';
import CardAttendance from 'src/pages/routeSheet/components/CardAttendance';
import CardDispatch from 'src/pages/routeSheet/components/CardDispatch';

interface CommentShiftAttendanceProps {
  assistance: RouteSheetResource[];
}
const CommentShiftAttendance = ({
  assistance,
}: CommentShiftAttendanceProps) => {
  const theme = useTheme();
  const isSmDisplay = useMediaQuery(theme.breakpoints.down('md'));
  const [itemsToShow, setItemsToShow] = useState<number>(1);
  const [assistanceValue, setAssistanceValue] = useState<RouteSheetResource>();
  const [dispatch, setDispatch] = useState<DispatchResource[]>([]);

  const handleChange = (event: SelectChangeEvent<string | number>): void => {
    const assistanceId = event.target.value.toString();
    selectedAssistance(assistanceId);
  };

  const selectedAssistance = (assistanceId: string) => {
    const selected = assistance.find((x) => x.id === assistanceId);
    if (selected !== undefined) {
      setAssistanceValue(selected);

      setDispatch(
        selected.dispatches?.sort((a, b) =>
          descendingComparatorDate(new Date(a.startDate), new Date(b.startDate))
        ) ?? []
      );
      showless();
    }
  };

  const showmore = () => setItemsToShow(dispatch.length);

  const showless = () => setItemsToShow(1);

  useEffect(() => {
    if (assistance.length > 0) selectedAssistance(assistance[0].id);

    if (assistance.length > 0) return selectedAssistance(assistance[0].id);
  }, [assistance]);

  return (
    <Card
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: '#0093b3',
          color: '#fff',
        }}
        title="Agregar Observaciones en Hoja de Ruta"
      />
      <Box sx={{ p: 2 }}>
        <Stack
          direction={'column'}
          justifyContent="flex-start"
          spacing={3}
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            sx={{
              gap: 2,
              '@media screen and (max-width: 1000px)': {
                flexDirection: 'column',
              },
            }}
          >
            <FormControl
              sx={{
                backgroundColor: 'background.paper',
              }}
            >
              <InputLabel id="assistance-simple-select-label">
                Asistencia
              </InputLabel>
              <Select
                labelId="assistance-simple-select-label"
                id="select-assistance"
                value={assistanceValue !== undefined ? assistanceValue.id : ''}
                label="Asistencia"
                onChange={handleChange}
              >
                {assistance.map((view) => (
                  <MenuItem
                    key={view.id}
                    value={view.id}
                  >
                    {view.attendanceStart && formatDate(view.attendanceStart)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {assistanceValue !== undefined &&
              assistance
                .filter((x) => x.id === assistanceValue.id)
                .map((assistanceMap) => {
                  if (
                    assistanceMap.attendanceType === AttendanceType.Worked ||
                    assistanceMap.attendanceType === AttendanceType.ExtraShift
                  ) {
                    return (
                      <CardAttendance
                        key={assistanceMap.id}
                        assistance={assistanceMap}
                        maxCharacter={500}
                      />
                    );
                  }
                  return (
                    <Stack
                      key={assistanceMap.id}
                      height={100}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h5"
                        component="h2"
                        align="center"
                        color="#0093b3"
                      >
                        {assistanceMap.attendanceTypeName}
                      </Typography>
                    </Stack>
                  );
                })}
          </Stack>
          {assistanceValue !== undefined &&
            (assistanceValue.attendanceType === AttendanceType.Worked ||
              assistanceValue.attendanceType === AttendanceType.ExtraShift) && (
              <>
                <Typography
                  variant="h6"
                  component="h2"
                  align="center"
                  color="#0093b3"
                >
                  Despachos del día
                </Typography>
                <Box
                  sx={{
                    height: isSmDisplay ? 'none' : 300,
                    overflow: 'auto',
                  }}
                >
                  <Scrollbar>
                    <Stack direction="column">
                      {dispatch.length > 0 &&
                        dispatch
                          .slice(0, itemsToShow)
                          .map((dispatch, index) => {
                            if (assistanceValue.attendanceStart === null)
                              return null;
                            return (
                              <CardDispatch
                                key={index}
                                rut={assistanceValue.rut}
                                attendanceStart={
                                  assistanceValue.attendanceStart
                                }
                                dispatch={dispatch}
                                maxCharacter={250}
                              />
                            );
                          })}

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          py: itemsToShow === 1 ? 5 : 1,
                        }}
                      >
                        {dispatch.length > 1 && itemsToShow === 1 && (
                          <Button
                            variant="outlined"
                            onClick={showmore}
                          >
                            Mostrar más
                          </Button>
                        )}

                        {dispatch.length > 0 && itemsToShow > 1 && (
                          <Button
                            variant="outlined"
                            onClick={showless}
                          >
                            Ocultar
                          </Button>
                        )}
                      </Box>
                    </Stack>
                  </Scrollbar>
                </Box>
              </>
            )}
        </Stack>
      </Box>
    </Card>
  );
};

export default CommentShiftAttendance;
