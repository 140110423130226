import { Typography, styled } from '@mui/material';

const TypographyTheme = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.palette.text.primary
      : theme.palette.text.primary,
  padding: theme.spacing(1),
}));

export default TypographyTheme;
