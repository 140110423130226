import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';

// import { defaultLocale } from 'components/custom/datePicker/useDefaultRanges';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
// import { mockAndDevelopment } from 'src/app.config';
import React from 'react';
import './main.css';

// if (process.env.NODE_ENV !== 'production' && mockAndDevelopment()) {
//   // eslint-disable-next-line @typescript-eslint/no-floating-promises, no-console
//   import('./__mocks__').then(() => console.debug('Se ha cargado mock!'));
// }

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <SettingsProvider>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </SettingsProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
