import ArchiveIcon from 'icons/Archive';
import UserIcon from 'icons/User';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from 'icons/Home';
import { UserProfiles } from 'types/userProfiles';
import ContactMailIcon from '@mui/icons-material/ContactMail';

interface ISectionItem {
  title: string;
  path: string;
  icon?: JSX.Element;
  children?: ISectionItem[];
}

export const NameMap: Record<string, string> = {
  '/home': 'Inicio',
  '/reports': 'Reportes',
  '/reports/attendance': 'Asistencia',
  '/reports/dailyAttendance': 'Jornada Diaria',
  '/reports/sundaysandholidays': 'Domingos y Festivos',
  '/reports/modifiedprogramming': 'Modificaciones y/o alteraciones de turnos',
  '/reports/emailStatus': 'Estado Correos',
  '/annexes': 'Anexos',
  '/annexes/routeSheet': 'Hoja de Ruta',
  '/annexes/commentShiftAttendance': 'Observaciones',
  '/annexes/programming': 'Programación',
  '/annexes/driverSummary': 'Resumen Trabajado',
  '/deals/driver/:id': 'Mis Pactos',
  '/deals/deal': 'Pactos HHEE',
  '/userAccounts': 'Usuarios',
  '/userAccounts/detail': 'Detalle',
  '/userAccounts/edit': 'Editar',
  '/reports/dailyControl': 'Control Diario',
  '/reports/incidentReport': 'Incidentes',
  '/searchAttendance': 'Buscar Asistencia',
  '/driverMailConfig': 'Configuración Correos',
};

const sectionReport: ISectionItem[] = [
  {
    title: NameMap['/reports/attendance'],
    path: '/reports/attendance',
  },
  {
    title: NameMap['/reports/dailyAttendance'],
    path: '/reports/dailyAttendance',
  },
  {
    title: NameMap['/reports/sundaysandholidays'],
    path: '/reports/sundaysandholidays',
  },
  {
    title: NameMap['/reports/modifiedprogramming'],
    path: '/reports/modifiedprogramming',
  },
  {
    title: NameMap['/reports/dailyControl'],
    path: '/reports/dailyControl',
  },
  {
    title: NameMap['/reports/emailStatus'],
    path: '/reports/emailStatus',
  },
  {
    title: NameMap['/reports/incidentReport'],
    path: '/reports/incidentReport',
  },
];

// const sectionDealDriver: ISectionItem[] = [
//   {
//     title: 'Mis Pactos de HHEE',
//     path: '/deals/hhee-driver',
//   },
// ];

// const sectionDeal: ISectionItem[] = [
//   {
//     title: NameMap['/deals/deal'],
//     path: '/deals/deal',
//   },
// ];

const sectiAnnexes: ISectionItem[] = [
  {
    title: NameMap['/annexes/routeSheet'],
    path: '/annexes/routeSheet',
  },
  {
    title: NameMap['/annexes/programming'],
    path: '/annexes/programming',
  },
  {
    title: NameMap['/annexes/driverSummary'],
    path: '/annexes/driverSummary',
  },
];
const sectionsNames = () => {
  return [
    {
      title: NameMap['/home'],
      path: '/',
      icon: <HomeIcon fontSize="small" />,
    },
    {
      title: NameMap['/userAccounts'],
      path: '/userAccounts',
      icon: <UserIcon fontSize="small" />,
    },
    {
      title: NameMap['/driverMailConfig'],
      path: '/driverMailConfig',
      icon: <ContactMailIcon fontSize="small" />,
    },
    {
      title: NameMap['/annexes'],
      path: '/annexes',
      icon: <ArchiveIcon fontSize="small" />,
      children: sectiAnnexes,
    },
    {
      title: NameMap['/reports'],
      path: '/reports',
      icon: <ArchiveIcon fontSize="small" />,
      children: sectionReport,
    },
    {
      title: NameMap['/deals/deal'],
      path: '/deals/deal',
      icon: <ArchiveIcon fontSize="small" />,
    },
    // {
    //   title: 'Mis Pactos',
    //   path: `/deals/driver/${id}`,
    //   icon: <ArchiveIcon fontSize="small" />,
    // },
    {
      title: NameMap['/searchAttendance'],
      path: '/searchAttendance',
      icon: <SearchIcon fontSize="small" />,
    },
  ] as ISectionItem[];
};

interface Section {
  title: string;
  items: ISectionItem[];
}
const getSections = (profiles: UserProfiles, id: string): Section[] => {
  const persmission = false;
  switch (profiles) {
    case UserProfiles.Conductor: {
      // statements;
      if (persmission) {
        return [
          {
            title: 'General',
            items: [
              {
                title: NameMap['/home'],
                path: '/',
                icon: <HomeIcon fontSize="small" />,
              },
              {
                title: NameMap['/annexes'],
                path: '/annexes',
                icon: <ArchiveIcon fontSize="small" />,
                children: sectiAnnexes,
              },
              {
                title: NameMap['/reports'],
                path: '/reports',
                icon: <ArchiveIcon fontSize="small" />,
                children: sectionReport.filter(
                  (x) =>
                    x.path !== '/reports/dailyControl' &&
                    x.path !== '/reports/excess'
                ),
              },
              {
                title: 'Mis Pactos',
                path: `/deals/driver/${id}`,
                icon: <ArchiveIcon fontSize="small" />,
              },
              {
                title: NameMap['/searchAttendance'],
                path: '/searchAttendance',
                icon: <SearchIcon fontSize="small" />,
              },
            ],
          } as Section,
        ];
      }
      return [
        {
          title: 'General',
          items: [
            {
              title: NameMap['/home'],
              path: '/',
              icon: <HomeIcon fontSize="small" />,
            },
            {
              title: NameMap['/annexes'],
              path: '/annexes',
              icon: <ArchiveIcon fontSize="small" />,
              children: sectiAnnexes,
            },
          ],
        } as Section,
      ];
    }
    case UserProfiles.Administrativo: {
      // statements;
      return [
        {
          title: 'General',
          items: [
            {
              title: NameMap['/home'],
              path: '/',
              icon: <HomeIcon fontSize="small" />,
            },
            {
              title: NameMap['/userAccounts'],
              path: '/userAccounts',
              icon: <UserIcon fontSize="small" />,
            },
            {
              title: NameMap['/annexes'],
              path: '/annexes',
              icon: <ArchiveIcon fontSize="small" />,
              children: sectiAnnexes,
            },
            {
              title: NameMap['/reports'],
              path: '/reports',
              icon: <ArchiveIcon fontSize="small" />,
              children: sectionReport.filter(
                (x) => x.path !== '/reports/excess'
              ),
            },
            {
              title: NameMap['/deals/deal'],
              path: '/deals/deal',
              icon: <ArchiveIcon fontSize="small" />,
            },
            // {
            //   title: 'Mis Pactos',
            //   path: `/deals/driver/${id}`,
            //   icon: <ArchiveIcon fontSize="small" />,
            // },
            {
              title: NameMap['/searchAttendance'],
              path: '/searchAttendance',
              icon: <SearchIcon fontSize="small" />,
            },
          ],
        } as Section,
      ];
    }
    case UserProfiles.Inspector: {
      // statements;
      return [
        {
          title: 'General',
          items: [
            {
              title: NameMap['/home'],
              path: '/',
              icon: <HomeIcon fontSize="small" />,
            },
            {
              title: 'Reportes',
              path: '/reports',
              icon: <ArchiveIcon fontSize="small" />,
              children: sectionReport.filter(
                (x) => x.path !== '/reports/dailyControl'
              ),
            },
            {
              title: NameMap['/searchAttendance'],
              path: '/searchAttendance',
              icon: <SearchIcon fontSize="small" />,
            },
          ],
        } as Section,
      ];
    }
    case UserProfiles.Admin: {
      return [
        {
          title: 'General',
          items: sectionsNames(),
        } as Section,
      ];
    }
    default: {
      // statements;
      return [
        {
          title: 'General',
          items: [],
        } as Section,
      ];
    }
  }
};
export default getSections;
