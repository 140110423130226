import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import MetbusLogo from 'src/components/MetbusLogo';
import VersionDisplayer from 'src/components/shared/VersionDisplayer';

import InspectorModal from 'src/components/authentication/inspectorAccount/InspectorModal';
import { LoginType } from 'src/types/loginData';
import useAuth from 'src/hooks/useAuth';
import { requestAccessByIP } from 'src/api/authenticate';
import AccountLoader from 'src/components/authentication/inspectorAccount/AccountLoader';
import CardLogin from 'src/components/authentication/login/CardLogin';
import LogoDT from 'src/icons/LogoDT';
import iconStyles from 'src/components/css/iconStyle';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';

const LoginInspector: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { setLoginType, loginByIp } = useAuth();
  const { settings } = useSettings();
  let contrastColor = false;
  if (settings.theme === THEMES.METBUSLIGHT) {
    contrastColor = true;
  }

  const getIpData = useCallback(() => {
    setLoading(true);
    requestAccessByIP()
      .then((response) => {
        if (response?.succeeded) {
          loginByIp(response);
        }
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getIpData();
    setLoginType(LoginType.INSPECTOR);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        py: '1rem',
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          gap: '2rem',
          height: '100%',
        }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
        >
          <LogoDT
            sx={{
              ...iconStyles,
              minHeight: '100px',
              minWidth: '100px',
              pr: '2rem',
            }}
          />
          <Stack
            direction={'column'}
            alignItems={'center'}
            gap={'1rem'}
          >
            <MetbusLogo
              height="72"
              width="240"
              contrastColor={contrastColor}
            />
            <Typography
              color="textSecondary"
              variant="body2"
              mb={2}
            >
              Acceso Dirección del Trabajo
            </Typography>
          </Stack>
        </Stack>
        {loading && <AccountLoader isInspector />}
        {!loading && (
          <CardLogin>
            <InspectorModal />
          </CardLogin>
        )}
        <VersionDisplayer horizontal />
      </Container>
    </Box>
  );
};

export default LoginInspector;
